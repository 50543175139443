import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Link, useLocation, useNavigate, useRoutes,
} from 'react-router-dom';
import { Menu, ModalContext } from '@alphakits/ui/dist';
import { PickerOptionButton } from 'src/components/picker/picker-option-button';
import { ChangePasswordModal } from 'src/modals/change-password';
import { UserModal } from 'src/modals/user';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import routes from 'src/router/routes';

const Routes = () => useRoutes(routes());

export const Root: React.FC = () => {
    const { current, superuser, redirect } = useCurrentUser();
    const { users } = useApi();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { openModal } = useContext(ModalContext);

    let pickerOptions = [
        {
            key: 1,
            content: (
                <PickerOptionButton
                    content={ t('Log out') }
                    onClick={ () => users.logout(undefined).then(() => redirect()) }
                />
            ),
        },
    ];

    if (!superuser) {
        pickerOptions = [
            ...pickerOptions,
            {
                key: 2,
                content: (
                    <PickerOptionButton
                        content={ t('Edit profile') }
                        onClick={ () => openModal(UserModal)({ recordId: current.user.id }) }
                    />
                ),
            },
            {
                key: 3,
                content: (
                    <PickerOptionButton
                        content={ t('Change password') }
                        onClick={ () =>
                            openModal(ChangePasswordModal)({ recordId: current.user.id }) }
                    />
                ),
            },
        ];
    }

    return (
        <Menu
            t={ t }
            pathname={ location.pathname }
            routes={ routes().filter((r) => !!r.icon) }
            userName={ `${current.user.name} ${current.user.surname}` }
            userDialogOptions={ pickerOptions }
            profileTitle="Love Rocket"
            profileImage="/android-chrome-192x192.png"
            Link={ Link }
            navigate={ navigate }
            userImage=""
        >
            <Routes />
        </Menu>
    );
};
