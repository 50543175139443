import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { BlacklistModal } from 'src/modals/blacklist';
import { useApi } from 'src/providers/api/context';

import { columns } from './columns';

export const BlacklistsPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);

    const openRequest = openModal(BlacklistModal);

    const { blacklists } = useApi();

    const { t } = useTranslation();

    return (
        <Table.TableComponent
            searchPlaceholder={ t('tables.phoneSearch') }
            onCreateClick={ (refetch) => openRequest(undefined, refetch) }
            onRowClick={ ({ id }, refetch) => openRequest({ recordId: id }, refetch) }
            columns={ columns(t) }
            promise={ blacklists.query }
            language="en"
        />
    );
};
