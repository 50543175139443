import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, PageLoading, Table } from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { UpdateScheduleDto } from '@lovelyvoyager/types/dist/girl/update-schedule.dto';
import { ScheduleModal } from 'src/modals/schedule';
import { ScheduleTemplateModal } from 'src/modals/schedule-template';
import { useApi } from 'src/providers/api/context';

import { columns } from './columns';

export const SchedulePage: React.FC = () => {
    const { t } = useTranslation();
    const { girls } = useApi();
    const { openModal } = useContext(ModalContext);

    const {
        findAllGirlsWithScheduleData,
        findAllGirlsWithScheduleError,
        findAllGirlsWithScheduleLoading,
        findAllGirlsWithScheduleFetch,
    } = useApiMethod(girls.findAllGirlsWithSchedule, {});

    const openScheduleModal = (initialValues: Partial<UpdateScheduleDto>, recordId?: number) => {
        openModal(ScheduleModal)({ initialValues, recordId }, () =>
            findAllGirlsWithScheduleFetch(undefined));
    };

    const openScheduleTemplateModal = (
        initialValues: Partial<UpdateScheduleDto>,
        recordId?: number,
    ) => {
        openModal(ScheduleTemplateModal)({ initialValues, recordId }, () =>
            findAllGirlsWithScheduleFetch(undefined));
    };

    useEffect(() => {
        findAllGirlsWithScheduleFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (findAllGirlsWithScheduleLoading) {
        return <PageLoading />;
    }

    if (findAllGirlsWithScheduleError) {
        return <div>{ findAllGirlsWithScheduleError.message }</div>;
    }

    if (!findAllGirlsWithScheduleData) {
        return null;
    }

    return (
        <React.Fragment>
            <Table.TableComponent
                searchPlaceholder={ t('tables.girlsSearch') }
                records={ findAllGirlsWithScheduleData }
                columns={ columns(t, openScheduleModal, openScheduleTemplateModal) }
                language="en"
            />
        </React.Fragment>
    );
};
