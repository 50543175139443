import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, Divider, HeaderSearch, Padding,
} from '@alphakits/ui/dist';
import { GirlRO } from '@lovelyvoyager/types/dist/girl/girl.ro';
import { PlusS } from 'alpha-cli-icons';
import { VerificationModalProps } from 'src/modals/verification';

import { EmptyList } from './empty-list';
import { GirlsList } from './girls-list';

import styles from './index.module.css';

type Props = {
    girls: GirlRO[];
    refetch: () => void;
    openOptionsModal: (girlId: number) => void;
    openGirlModal: (girlId?: number) => void;
    openVerificationModal: (props: VerificationModalProps) => void;
};

export const IndieTable: React.FC<Props> = ({
    girls,
    refetch,
    openGirlModal,
    openOptionsModal,
    openVerificationModal,
}) => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState('');

    const data = searchValue
        ? girls.filter((girl) => girl.name.toUpperCase().includes(searchValue.toUpperCase()))
        : girls;

    return (
        <Padding padding="0 32px">
            <div className={ styles.head }>
                <HeaderSearch
                    placeholder={ t('tables.girlsSearch') }
                    value={ searchValue }
                    onChange={ setSearchValue }
                />
                <Button
                    dataTestId="createbtn"
                    onClick={ () => openGirlModal() }
                    view="primary"
                    leftAddons={ <PlusS /> }
                    size="xs"
                >
                    { t('createIndie') }
                </Button>
            </div>

            <Divider size="m" />

            <GirlsList
                refetch={ refetch }
                openOptionsModal={ openOptionsModal }
                openGirlModal={ openGirlModal }
                openVerificationModal={ openVerificationModal }
                girls={ data }
            />

            { !girls.length && <EmptyList onClick={ () => openGirlModal() } /> }
        </Padding>
    );
};
