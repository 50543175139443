import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { ModalProvider } from '@alphakits/ui/dist';

import { Root } from './pages/root';
import { ApiProvider } from './providers/api/context';
import { CurrentUserProvider } from './providers/current-user/context';

import 'reflect-metadata';
import './locales/i18n';

function App() {
    const { t } = useTranslation();

    return (
        <Router>
            <ApiProvider>
                <CurrentUserProvider>
                    <ModalProvider t={ t }>
                        <Root />
                    </ModalProvider>
                </CurrentUserProvider>
            </ApiProvider>
        </Router>
    );
}

export default App;
