import React from 'react';
import { FlexColumns, Input, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { UpdateCurrentUserPasswordDto } from '@lovelyvoyager/types/dist/user/update-current-user-password.dto';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    close?: () => void;
    t: TFunction;
};

export const ChangePasswordModal: React.FC<Props> = ({ close, t }) => {
    const { users } = useApi();

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: users.currentUpdatePassword,
        close,
    });

    return (
        <React.Fragment>
            <RestForm
                title={ t('modalsHeader.editPassword') }
                initialValues={ serializeRecord(UpdateCurrentUserPasswordDto, {}) }
                validate={ validateByDto(t) }
                save={ save }
                close={ close }
                softError={ softError }
                updating={ updating }
                t={ t }
            >
                { ({
                    values, handleChange, errors, touched,
                }) => (
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            dataTestId="oldPassword"
                            label={ t('component.oldPassword') }
                            value={ values.oldPassword }
                            onChange={ handleChange('oldPassword') }
                            error={ touched.oldPassword && errors.oldPassword }
                            required={ true }
                            type="password"
                            block={ true }
                        />
                        <Input
                            dataTestId="newPassword"
                            label={ t('component.newPassword') }
                            value={ values.newPassword }
                            onChange={ handleChange('newPassword') }
                            error={ touched.newPassword && errors.newPassword }
                            required={ true }
                            type="password"
                            block={ true }
                        />
                        <Input
                            dataTestId="repeatPassword"
                            label={ t('component.repeatPassword') }
                            value={ values.repeatPassword }
                            onChange={ handleChange('repeatPassword') }
                            error={ touched.repeatPassword && errors.repeatPassword }
                            required={ true }
                            type="password"
                            block={ true }
                        />
                    </FlexColumns>
                ) }
            </RestForm>
        </React.Fragment>
    );
};
