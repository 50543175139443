/* eslint-disable quote-props */
export const TRANSLATIONS_DE = {
    save: 'Speichern',
    cancel: 'Abbrechen',
    create: 'Создать',
    export: 'Export',
    filters: 'Filter',
    saloon: 'Salon',
    apart: 'Apartments',
    extra: 'Extra',
    tattoo: 'Tattoo',
    languages: 'Languages',
    piercing: 'Piercing',
    smoker: 'Smoker',
    pornstar: 'Pornstar',
    girlOptions: 'Optionen',
    hairColor: 'Hair Color',
    publicHair: 'Public Hair',
    bustType: 'Bust type',
    hairLength: 'Hair Length',
    ethnicity: 'Ethnicity',
    orientation: 'Orientation',
    createSaloon: 'Salon hinzufügen',
    createIndie: 'Profil hinzufügen',
    noGirlsInSaloon: 'Es gibt momentan keine Profile',
    addGirl: 'Profil hinzufügen',
    served: 'Bietet folgende Service an: {{options}}',
    addOption: 'Service hinzufügen',
    needToAddOptions: 'Vergessen Sie nicht Service hinzufügen',
    userAccessed: 'Zugriff gewährt ',
    fullAccess: 'Voller Zugriff',
    userDisabled: 'Zugriff geschlossen',
    girlAccessed: 'Profil aktiviert',
    autocomplite: 'wird automatisch ausgefüllt',
    girlDisabled: 'Profil deaktiviert',
    status: 'Status',
    lastVisit: 'Letzter Besuch',
    access: 'Zugang',
    mainInfo: 'Hauptinfos',
    title: 'Name',
    type: 'Typ',
    address: 'Stadt und Adresse',
    phone: 'Telefon',
    description: 'Beschreibung',
    howToFind: 'Wie Sie uns finden',
    advantagesLocation: 'Vorteile',
    adviceLocation:
        'Wird für die Beschreibung im Katalog benutzt, um sich von der Konkurenz vorzuheben',
    wifi: 'W-Lan',
    freeParking: 'Kostenloses Parken',
    parking: 'Kostenpflichtiges Parken',
    shower: 'Dusche',
    waitingArea: 'Warteraum',
    photoUploaderTitle: 'Fotos',
    photoUploaderSubtitle: 'Werden im Katalog angezeigt. Minimale Bildauflösung 1440 х 1220.',
    linkVideo: 'Link auf Video',
    hiddenLinkVideo: 'Link verstecken',
    addLinkVideo: 'Link auf Video hinzufügen',

    reaction: 'Beziehung',
    saloonForm: 'Standorterstellung',
    girlForm: 'Profilerstellung',
    name: 'Vorname',
    surname: 'Name',
    params: 'Parameter',
    price: 'Preis',
    weight: 'Gewicht',
    girlPrice: 'Preis pro Stunde von',
    additional_price: 'Aufpreis',
    outdoorPrice: 'Outdoor Price',
    indoorPrice: 'Indoor Price',
    birthday: 'Geburtstag',
    height: 'Große',
    bustSize: 'Körbchen',
    footSize: 'Schuhgröße ',
    aboutGirl: 'Über mich',
    agesAllowed: 'Kundenalter',
    from: 'von',
    to: 'bis',

    nickname: 'Hier können Sie ein Pseudonym erstellen, das im Katalog angezeigt wird',
    requiredStreetCity:
        'Sie brauchen keine genaue Adressse einzugeben, aber unbedingt eine Stadt und eine Strasse',
    willShowSaloonPhone:
        'Es wird die Telefonnummer von Club angezeigt, sollte das Feld leer bleiben',
    willShowThisPhone: 'Diese Rufnummer wird angezeigt',
    minPrice:
        'Bitte Minimalpreis pro Stunde angeben. Aufpreise für bestimmte Servicearten können Sie in Einstellungen vornehmen',
    acceptOutdoor: 'Bitte geben Sie an wenn Sie Kunden besuchen',
    acceptIndoor: 'Geben Sie an, wenn Sie Kunden nur bei sich empfangen',
    whereAccepted:
        'Geben Sie an wo Sie Kunden Empfangen, sonst kann Ihr Profil nicht angezeigt werden',
    internalDocuments: 'eigene Infos',
    privateDocuments:
        'Die Bilder werden nur von unserem Moderator angeschaut. Für die Überprüfung müssen Sie ein Foto mit Ihrem Namen und Geburtstag auf einem Blatt Papier aufnehmen',

    requiredFields: 'Alle gekennzeichnete Felder mit Sternchen müssen ausgefüllt sein',
    fullAccessHint:
        'Benutzer mit vollem Zugriff haben die Möglichkeit alle Rubriken und Funktionen zu nutzen. Benutzer mit einem beschränkten Zugriff können nur Profilzeitpläne anzupassen',
    blocked: {
        title: 'Ваша анкета отключена',
        description:
            'Мы выявили грубые нарушения в вашей анкете или получили жалобу. В ближайшее время мы с вами свяжемся для разрешения вопроса',
    },
    accepted: {
        title: 'Ваша анкета на рассмотрении',
        description:
            'Вы успешно добавили анкету, в данный момент она находится на рассмотрении в отделе заботы',
    },
    moderation: {
        title: 'Анкета на провеке у модеатора',
        description: `Ваша анкета находится на проверке.
            После одобрения модератором она появится на сайте.
            Обычно это занимает от 2 до 8и рабочих часов.`,
        alert: 'Модератор выявил нарушение, пожалуйста исправьте данное нарушение и мы повторно рассмотрим анкету.',
    },

    haveQuestions: 'Wenn Sie Fragen haben, wenden Sie sich telefonisch an uns',

    invitationSent: 'Eine Einladung wurde versendet!',
    invitationNotSent: 'Es ist uns nicht gelungen eine Einladung zu versenden',
    primary: 'Haupt',

    editing: 'Bearbeitung',

    createdAt: 'Erstellungsdatum',
    text: 'Text',
    answer: 'Antworten',
    blacklistAdd: 'Zur schwarzen Liste hinzufügen',
    blacklistUpdate: 'Anzeigen / Bearbeiten',
    comment: 'Kommentar',

    error: {
        dataSuccessSaved: 'Alle Daten wurden erfolgreich gespeichert',
        success: 'Erfolg',
        error: 'Fehler',
    },

    menu: {
        '/': 'Fragebogen',
        '/users': 'Benutzer',
        '/payments': 'Zahlungen',
        '/schedule': 'Zeitplan',
        '/reviews': 'Bewertungen',
        '/blacklists': 'Schwarze Liste',
    },
    tables: {
        girlsSearch: 'Suche mit Namen',
        usersSearch: 'Suche mit Benutzernamen',
        reviewsSearch: 'Nach Bewertungstext suchen',
        phoneSearch: 'Letzte 4 Telefonnummern eingeben',
    },
    options: {
        saloonType: {
            saloon: 'Club',
            apart: 'Private Ambiente',
        },
        orientation: {
            getero: 'Getero',
            bi: 'Bi',
            lesbian: 'Lesbian',
        },
        bustType: {
            natural: 'Natural',
            silicon: 'Silicon',
        },
        ethnicity: {
            asian: 'Asian',
            arabian: 'Arabian',
            ebony: 'ebony',
            european: 'European',
            indian: 'Indian',
            latin: 'Latin',
            mongolia: 'Mongolia',
            mixed: 'Mixed',
        },
        publicHair: {
            shaved: 'shaved',
            trimmed: 'trimmed',
            natural: 'natural',
        },
        hairColor: {
            blonde: 'blonde',
            brown: 'brown',
            black: 'black',
            red: 'red',
        },
        hairLength: {
            short: 'Short',
            medium: 'Medium',
            long: 'Long',
        },
        bustSize: {
            A: 'A',
            B: 'B',
            C: 'C',
            D: 'D',
            E: 'E',
            F: 'F',
            G: 'G',
            H: 'H',
        },
        type: {
            male: 'Male',
            female: 'Female',
            trans: 'Trans',
            duo: 'Duo',
            couple: 'Couple',
        },
        girlsOptions: {
            anal_sex: 'Analsex',
            group_sex: 'Gruppensex',
            lesbian_sex: 'Lesbischer Sex',
            blowjob_safe: 'Kondom-Blowjob',
            blowjob_unsafe: 'Blowjob ohne Kondom',
            blowjob_deep: 'Kehlenfick',
            classic_sex: 'Klassischer Sex',
            handjob: 'handjob',
            fisting: 'fisting',
            bdsm: 'bdsm',
            domination: 'domination',
            foot_fetish: 'foot_fetish',
            kisses: 'kisses',
        },

        girlsOptionsReactionType: {
            do: 'Mache ich',
            love: 'Liebe ich',
            simphaty: 'nach meinem Ermessen',
            never: 'Mache ich nicht',
        },

        scheduleType: {
            all_day: 'den ganzen Tag',
            weekend: 'freier Tag',
            undefined: 'nicht eingestellt',
            custom_time: 'Zeit angeben',
        },
    },
    calendar: {
        weekDays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
        monthes: [
            'Januar',
            'Febraur',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
        ],
    },

    whatsapp: 'Können Besucher WhatsApp schreiben?',
    telegram: 'Geben Sie den Spitznamen in das Telegramm ein',
    enterTelegram: 'Spitzname Telegramm',
    errors: {
        isNotBlankString: 'Поле обязательно для заполнения',
        isNotBlank: 'Поле обязательно для заполнения',
        isEnum: 'Поле обязательно для заполнения',
        isNumber: 'Поле обязательно для заполнения',
        isArray: 'Поле обязательно для заполнения',
        arrayMinSize: 'Минимальное значение {{count}} элемента',
        arrayMaxSize: 'Максимальное значение {{count}} элемента',
        maxLength: 'Максимальная длина {{count}} ',
        minLength: 'Минимальная длина {{count}}',
    },
};
