import React from 'react';
import { Button, showError, showSuccess } from '@alphakits/ui';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { useLocalStorage } from 'src/hooks/use-local-storage';
import { useApi } from 'src/providers/api/context';

export const SendInviteButton = ({ id, t }) => {
    const { users: usersApi } = useApi();

    const [invitedUsers, setInvitedUsers] = useLocalStorage('invitedUsers', []);

    const { sendInviteFetch, sendInviteLoading } = useApiMethod(usersApi.sendInvite, {
        successCallback: ({ data: { id: userId } }) => {
            const idsToStore = invitedUsers?.includes(userId)
                ? invitedUsers
                : [...invitedUsers, userId];

            setInvitedUsers(idsToStore);
            showSuccess('Sended');
        },
        errorCallback: (err) => showError(err.message),
    });
    const text = invitedUsers.includes(id) ? t('sendInviteRepeat') : t('sendInvite');

    return (
        <Button
            loading={ sendInviteLoading }
            size="xs"
            onClick={ (e) => {
                e.stopPropagation();
                sendInviteFetch(id);
            } }
        >
            { text }
        </Button>
    );
};
