import React from 'react';
import { Message } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ReviewRO } from '@lovelyvoyager/types/dist/review/review.ro';
import { format, getDate, getYear } from 'date-fns';
import { TFunction } from 'i18next';

import styles from './index.module.css';

export const getFormatDate = (date: Date) =>
    `${getDate(date)} ${format(date, 'LLLL')} ${getYear(date)}`;

export const columns = (t: TFunction): TableColumns<ReviewRO> => [
    {
        header: t('createdAt'),
        accessor: 'createdAt',
        row: {
            title: ({ row }) => (
                <Message
                    className={ styles.message }
                    title={ row.name }
                    text={ row.text }
                    withImage={ true }
                    image=""
                    date={ getFormatDate(new Date(row.createdAt)) }
                    rightAddons={ <React.Fragment>{ row.delta }</React.Fragment> }
                />
            ),
        },
    },

    {
        header: t('answer'),
        accessor: 'answer',
    },
];
