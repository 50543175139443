import React from 'react';
import { NotFound } from '@alphakits/ui/dist';
import {
    CalendarOutlineM,
    MessageMarkM,
    PersonMinusM,
    PersonsManyM,
    PersonsTwoM,
} from 'alpha-cli-icons';
import { BlacklistsPage } from 'src/pages/blacklists';
import { GirlsPage } from 'src/pages/girls';
import { ReviewsPage } from 'src/pages/reviews';
import { SchedulePage } from 'src/pages/schedule';
import { UsersPage } from 'src/pages/users';

import {
    BLACKLISTS, REVIEWS, SCHEDULE, USERS,
} from './constants';

const routes = () => [
    {
        path: '/',
        element: <GirlsPage />,
        icon: <PersonsTwoM />,
    },
    {
        path: SCHEDULE,
        element: <SchedulePage />,
        icon: <CalendarOutlineM />,
    },
    {
        path: USERS,
        element: <UsersPage />,
        icon: <PersonsManyM />,
    },
    {
        path: REVIEWS,
        element: <ReviewsPage />,
        icon: <MessageMarkM />,
    },
    {
        path: BLACKLISTS,
        element: <BlacklistsPage />,
        icon: <PersonMinusM />,
    },
    {
        element: (
            <NotFound
                title="OOOPS"
                code="404"
                buttonText="Вернуться на главную"
                subtitle="Похоже, что такой страницы не существует"
                href="/"
            />
        ),
        path: '*',
    },
];

export default routes;
