import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { UserModal } from 'src/modals/user';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { columns } from './columns';

export const UsersPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { t } = useTranslation();
    const { current, superuser } = useCurrentUser();

    const { users } = useApi();

    return (
        <React.Fragment>
            <Table.TableComponent
                searchPlaceholder={ t('tables.usersSearch') }
                onRowClick={ ({ id }) => openModal(UserModal)({ recordId: id }) }
                promise={ users.query }
                columns={ columns(t, current.user, superuser) }
                onCreateClick={ (refetch) =>
                    (superuser || current.user.master ? openModal(UserModal)({}, refetch) : undefined) }
                language="en"
            />
        </React.Fragment>
    );
};
