import React from 'react';
import {
    Checkbox,
    Collapse,
    Divider,
    FlexColumns,
    ImageUploader,
    Input,
    SuperListHeader,
    Textarea,
    Typography,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { UpdateSaloonDto } from '@lovelyvoyager/types/dist/saloon/update-saloon.dto';
import { ImageTypeEnum } from '@lovelyvoyager/types/dist/upload/enum/image-type.enum';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    recordId?: number;
    close?: () => void;
    t: TFunction;
};

export const SaloonModal: React.FC<Props> = ({ recordId, close, t }) => {
    const { saloons, uploads } = useApi();

    const { loading, record, error } = useGetOne(recordId, saloons.getOneSaloon);

    const {
        updating,
        save,
        remove,
        removing,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: recordId ? saloons.updateSaloon : saloons.createSaloon,
        removeRequest: saloons.removeSaloon,
        close,
    });

    const title = recordId ? record.title : t('saloonForm');

    return (
        <RestForm
            recordId={ +recordId }
            title={ title }
            initialValues={ serializeRecord(UpdateSaloonDto, record) }
            validate={ validateByDto(t) }
            close={ close }
            remove={ remove }
            save={ save }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            dataTestId="address"
                            disabled={ !!recordId }
                            label={ t('address') }
                            value={ values.address }
                            onChange={ handleChange('address') }
                            error={ touched.address && errors.address }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            dataTestId="title"
                            label={ t('title') }
                            value={ values.title }
                            onChange={ handleChange('title') }
                            error={ touched.title && errors.title }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            dataTestId="phone"
                            label={ t('phone') }
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            error={ touched.phone && errors.phone }
                            required={ true }
                            block={ true }
                        />

                        <Textarea
                            label={ t('description') }
                            value={ values.description }
                            onChange={ handleChange('description') }
                            required={ true }
                        />

                        <FlexColumns columns={ 1 } gr={ 0 } gc={ 0 }>
                            <SuperListHeader text={ t('advantagesLocation') } />

                            <Typography.Text view="primary-small" color="secondary" weight="medium">
                                { t('adviceLocation') }
                            </Typography.Text>
                        </FlexColumns>

                        <Checkbox
                            dataTestId="wifi"
                            label={ t('wifi') }
                            checked={ values.wifi }
                            onChange={ (e, { checked }) => setFieldValue('wifi', checked) }
                        />

                        <Checkbox
                            dataTestId="freeParking"
                            label={ t('freeParking') }
                            checked={ values.freeParking }
                            onChange={ (e, { checked }) => setFieldValue('freeParking', checked) }
                        />
                        <Checkbox
                            dataTestId="parking"
                            label={ t('parking') }
                            checked={ values.parking }
                            onChange={ (e, { checked }) => setFieldValue('parking', checked) }
                        />
                        <Checkbox
                            dataTestId="shower"
                            label={ t('shower') }
                            checked={ values.shower }
                            onChange={ (e, { checked }) => setFieldValue('shower', checked) }
                        />
                        <Checkbox
                            dataTestId="waitingArea"
                            label={ t('waitingArea') }
                            checked={ values.waitingArea }
                            onChange={ (e, { checked }) => setFieldValue('waitingArea', checked) }
                        />

                        <Divider size="s" />

                        <ImageUploader
                            title={ `${t('photoUploaderTitle')} *` }
                            subtitle={ t('photoUploaderSubtitle') }
                            value={ values.photos }
                            main={ values.photo }
                            onUpdate={ (value) => {
                                if (!values.photo) {
                                    setFieldValue('photo', value[0]);
                                }
                                setFieldValue('photos', value);
                            } }
                            setMain={ (value) => setFieldValue('photo', value) }
                            error={ touched.photo && (errors.photo as string) }
                            type={ ImageTypeEnum.SALOON }
                            multiple={ true }
                            uploadRequest={ uploads.uploadImage }
                        />

                        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                            <Collapse
                                collapsedLabel={ t('addLinkVideo') }
                                expandedLabel={ t('hiddenLinkVideo') }
                                dataTestId="addLinkVideo"
                            >
                                <Input
                                    dataTestId="video"
                                    label={ t('linkVideo') }
                                    value={ values.video }
                                    onChange={ handleChange('video') }
                                    block={ true }
                                />
                            </Collapse>
                        </FlexColumns>
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
