import React from 'react';
import { FormattedDate } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { UserResponseObject } from '@lovelyvoyager/types/dist/user/user.ro';

import { SendInviteButton } from './send-invite-button';
import { SwitchAccess } from './switch-access';

export const columns = (t, user, superuser): TableColumns<UserResponseObject> => [
    {
        header: t('name'),
        row: {
            title: ({ row }) => `${row.name} ${row.surname}`,
        },
    },
    {
        header: t('email'),
        accessor: 'email',
        row: {
            subtitle: 'phone',
        },
    },
    {
        header: t('lastVisit'),
        row: {
            title: ({ row }) => (
                <React.Fragment>
                    { row.lastAuth ? (
                        <FormattedDate
                            view="title"
                            weight="medium"
                            date={ row.lastAuth }
                            format="dd.MM.yy, HH:mm"
                        />
                    ) : (
                        <SendInviteButton id={ row.id } t={ t } />
                    ) }
                </React.Fragment>
            ),
        },
    },
    {
        header: t('access'),
        row: {
            title: ({ row, refetch }) => {
                if (row.master) return 'Full access';

                return (superuser || user.master) && <SwitchAccess refetch={ refetch } user={ row } />;
            },
        },
    },
];
