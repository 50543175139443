import React from 'react';
import { FormattedDate } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { BlacklistRO } from '@lovelyvoyager/types/dist/blacklist/blacklist.ro';
import { TFunction } from 'i18next';

export const columns = (t: TFunction): TableColumns<BlacklistRO> => [
    {
        header: t('phone'),
        accessor: 'phone',
    },
    {
        header: t('createdAt'),
        row: {
            title: ({ row }) => (
                <FormattedDate
                    view="title"
                    weight="medium"
                    date={ row.createdAt }
                    format="dd.MM.yy, HH:mm"
                />
            ),
        },
    },
    {
        header: t('comment'),
        accessor: 'comment',
    },
];
