import React from 'react';
import { Loader } from '@alphakits/ui';

import styles from './index.module.css';

export const Preloader = () => (
    <div className={ styles.component }>
        <Loader />
    </div>
);
