import React from 'react';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { GirlWithScheduleRO } from '@lovelyvoyager/types/dist/girl/girl-with-schedule.ro';
import { ScheduleRO } from '@lovelyvoyager/types/dist/girl/schedule.ro';
import { UpdateScheduleDto } from '@lovelyvoyager/types/dist/girl/update-schedule.dto';
import { addDays, format } from 'date-fns';

import { ScheduleCell } from './schedule-cell';

const getDays = (
    openScheduleModal: (initialValues: Partial<UpdateScheduleDto>, recordId?: number) => void,
): TableColumns<GirlWithScheduleRO> =>
    new Array(5).fill('1').map((_, index) => {
        const d = addDays(new Date(), index);
        const date = format(d, 'yyyy-MM-dd');

        return {
            header: format(d, 'dd MMM'),
            accessor: '',
            row: {
                title: ({ row }) => {
                    const schedule =
                        row.schedule?.filter((s) => s.date === date)[0] ||
                        (row.scheduleTemplate as ScheduleRO);
                    const values = {
                        type: schedule.type,
                        start: schedule?.start,
                        end: schedule?.start,
                        girlId: row.id,
                        date,
                    };

                    const handleClick = () => openScheduleModal(values, schedule?.id);

                    return (
                        <ScheduleCell
                            onClick={ handleClick }
                            type={ values.type }
                            start={ values.start }
                            end={ values.end }
                        />
                    );
                },
            },
        };
    }) as unknown as TableColumns<GirlWithScheduleRO>;

export const columns = (
    t,
    openScheduleModal,
    openScheduleTemplateModal,
): TableColumns<GirlWithScheduleRO> => [
    {
        header: t('name'),
        accessor: 'name',
        row: {
            image: 'photo',
        },
    },
    ...getDays(openScheduleModal),
    {
        header: t('autocomplite'),
        accessor: null,
        row: {
            title: ({ row }) => (
                <ScheduleCell
                    onClick={ () => openScheduleTemplateModal(row.scheduleTemplate, row.id) }
                    type={ row.scheduleTemplate.type }
                    start={ row.scheduleTemplate.start }
                    end={ row.scheduleTemplate.end }
                />
            ),
        },
    },
];
