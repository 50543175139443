/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, showError, Switch } from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { useApi } from 'src/providers/api/context';

type Props = {
    visible: boolean;
    girlId: number;
    refetch: () => void;
};

export const SwitchVisibility: React.FC<Props> = ({ visible, girlId, refetch }) => {
    const { t } = useTranslation();

    const { girls } = useApi();

    const { toggleGirlVisibilityFetch, toggleGirlVisibilityLoading } = useApiMethod(
        girls.toggleGirlVisibility,
        {
            successCallback: refetch,
            errorCallback: (err) => showError(err.message),
        },
    );

    if (toggleGirlVisibilityLoading) return <Loader />;

    return (
        <div onClick={ (e) => e.stopPropagation() }>
            <Switch
                label={ visible ? t('girlAccessed') : t('girlDisabled') }
                checked={ visible }
                onChange={ () => {
                    toggleGirlVisibilityFetch(girlId);
                } }
            />
        </div>
    );
};
