import React from 'react';
import { IconButton, Typography } from '@alphakits/ui/dist';
import { EditS } from 'alpha-cli-icons';

import styles from './index.module.css';

type Props = {
    title: string;
    onClick: () => void;
};

export const Header: React.FC<Props> = ({ onClick, title }) => (
    <React.Fragment>
        <Typography.Text view="title" weight="bold" color="primary">
            { title }
        </Typography.Text>

        <IconButton
            className={ styles.button }
            icon={ EditS }
            onClick={ onClick }
            size="s"
            view="ghost"
        />
    </React.Fragment>
);
