/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Loader, showError, Switch } from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { UserResponseObject } from '@lovelyvoyager/types/dist/user/user.ro';
import { useApi } from 'src/providers/api/context';

type Props = {
    user: UserResponseObject;
    refetch: () => void;
};

export const SwitchAccess: React.FC<Props> = ({ user, refetch }) => {
    const { users } = useApi();
    const { toggleAccessFetch, toggleAccessLoading } = useApiMethod(users.toggleAccess, {
        successCallback: refetch,
        errorCallback: (err) => showError(err.message),
    });

    if (toggleAccessLoading) return <Loader />;

    return (
        <div onClick={ (e) => e.stopPropagation() }>
            <Switch
                checked={ user.access }
                onChange={ () => {
                    toggleAccessFetch(user.id);
                } }
                label={ user.access ? 'Partial access' : 'Disabled' }
            />
        </div>
    );
};
