import React, { useContext, useEffect } from 'react';
import { ModalContext, PageLoading } from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { GirlRO } from '@lovelyvoyager/types/dist/girl/girl.ro';
import { ProfileTypeEnum } from '@lovelyvoyager/types/dist/profile/enum/profile-type.enum';
import { SaloonRO } from '@lovelyvoyager/types/dist/saloon/saloon.ro';
import { GirlModal } from 'src/modals/girl';
import { OptionsModal } from 'src/modals/options';
import { SaloonModal } from 'src/modals/saloon';
import { VerificationModal, VerificationModalProps } from 'src/modals/verification';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { IndieTable } from './components/indie-table';
import { SaloonTable } from './components/saloon-table';

export type SallonWithGirls = SaloonRO & {
    girls: GirlRO[];
};

export const GirlsPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);

    const {
        profile: { type },
    } = useCurrentUser();

    const api = useApi();

    const {
        getAllGirlsData, getAllGirlsError, getAllGirlsLoading, getAllGirlsFetch,
    } =
        useApiMethod(api.girls.getAllGirls, {});

    const {
        getAllSaloonsData, getAllSaloonsError, getAllSaloonsFetch, getAllSaloonsLoading,
    } =
        useApiMethod(api.saloons.getAllSaloons, {});

    useEffect(() => {
        getAllGirlsFetch(undefined);
        getAllSaloonsFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (getAllGirlsLoading || getAllSaloonsLoading) {
        return <PageLoading />;
    }

    if (getAllGirlsError || getAllSaloonsError) {
        return <div>Error</div>;
    }

    if (!getAllGirlsData || !getAllSaloonsData) {
        return null;
    }

    const openGirlModal = (saloonId?: number, recordId?: number) => {
        openModal(GirlModal)({ saloonId, recordId, saloons: getAllSaloonsData }, () =>
            getAllGirlsFetch(undefined));
    };

    const openSaloonModal = (recordId?: number) => {
        openModal(SaloonModal)({ recordId }, () => {
            getAllSaloonsFetch(undefined);
        });
    };

    const openOptionsModal = (recordId: number) => {
        openModal(OptionsModal, 'l')({ recordId }, () => getAllGirlsFetch(undefined));
    };

    const openVerificationModal = (props: VerificationModalProps) => {
        openModal(VerificationModal)(props, () => getAllGirlsFetch(undefined));
    };

    if (type === ProfileTypeEnum.SALOON) {
        return (
            <SaloonTable
                openVerificationModal={ openVerificationModal }
                openGirlModal={ openGirlModal }
                openSaloonModal={ openSaloonModal }
                openOptionsModal={ openOptionsModal }
                girls={ getAllGirlsData }
                saloons={ getAllSaloonsData }
                refetch={ () => getAllGirlsFetch(undefined) }
            />
        );
    }

    return (
        <IndieTable
            openVerificationModal={ openVerificationModal }
            openGirlModal={ openGirlModal }
            openOptionsModal={ openOptionsModal }
            girls={ getAllGirlsData }
            refetch={ () => getAllGirlsFetch(undefined) }
        />
    );
};
