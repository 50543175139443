import React, { useEffect } from 'react';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { ProfileResponseObject } from '@lovelyvoyager/types/dist/profile/profile.ro';
import { CurrentUserResponseObject } from '@lovelyvoyager/types/dist/user/current-user.ro';
import { useApi } from 'src/providers/api/context';
import { Preloader } from 'src/templates/preloader';

type Context = {
    current?: CurrentUserResponseObject;
    profile?: ProfileResponseObject;
    superuser?: boolean;
    redirect?: () => void;
};

export const CurrentUserContext = React.createContext<Context>({});

export const CurrentUserProvider = ({ children }) => {
    const { users, profiles } = useApi();

    const {
        meData, meFetch, meLoading, meError,
    } = useApiMethod(users.me, {});

    const {
        currentData, currentFetch, currentLoading, currentError,
    } = useApiMethod(
        profiles.current,
        {},
    );

    useEffect(() => {
        meFetch(undefined);
        currentFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirect = () => {
        window.location.assign(`${process.env.REACT_APP_AUTH_DOMAIN}/`);
    };

    useEffect(() => {
        if (meError || currentError) {
            redirect();
        }
    }, [meError, currentError]);

    if (meLoading || currentLoading || !currentData || !meData) return <Preloader />;

    return (
        <CurrentUserContext.Provider
            value={ {
                redirect,
                current: meData,
                profile: currentData,
                superuser: meData.user.id === 999999999,
            } }
        >
            { children }
        </CurrentUserContext.Provider>
    );
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);
