import React from 'react';
import { Button, ButtonProps } from '@alphakits/ui/dist';

import styles from './index.module.css';

type PickerOptionButtonProps = {
    content: React.ReactNode | string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (e: any) => void;
} & Pick<ButtonProps, 'dataTestId' | 'leftAddons' | 'rightAddons' | 'nowrap'>;

export const PickerOptionButton = ({
    content,
    onClick,
    ...restButtonProps
}: PickerOptionButtonProps) => (
    <div className={ styles.option }>
        <Button
            block={ true }
            view="ghost"
            size="xs"
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onClick={ onClick }
            { ...restButtonProps }
        >
            { content }
        </Button>
    </div>
);
