import React from 'react';
import { InitApiDeclaration, initClientApi } from '@lovelyvoyager/services/dist/api/init-client-api';

type Context = InitApiDeclaration | null;

export const ApiContext = React.createContext<Context>(null);

export const ApiProvider = ({ children }) => (
    <ApiContext.Provider value={ initClientApi(process.env.REACT_APP_API_URL) }>
        { children }
    </ApiContext.Provider>
);

export const useApi = () => React.useContext(ApiContext);
