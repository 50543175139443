import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CollapsableRow, HeaderSearch } from '@alphakits/ui/dist';
import { GirlRO } from '@lovelyvoyager/types/dist/girl/girl.ro';
import { SaloonRO } from '@lovelyvoyager/types/dist/saloon/saloon.ro';
import { PlusS } from 'alpha-cli-icons';
import { VerificationModalProps } from 'src/modals/verification';
import { useCurrentUser } from 'src/providers/current-user/context';

import { EmptyList } from './empty-list';
import { GirlsList } from './girls-list';
import { Header } from './header';

import styles from './index.module.css';

type Props = {
    girls: GirlRO[];
    saloons: SaloonRO[];
    refetch: () => void;
    openSaloonModal: (saloonId?: number) => void;
    openGirlModal: (girlId?: number) => void;
    openOptionsModal: (girlId: number) => void;
    openVerificationModal: (props: VerificationModalProps) => void;
};

export const SaloonTable: React.FC<Props> = ({
    saloons,
    girls,
    refetch,
    openSaloonModal,
    openGirlModal,
    openOptionsModal,
    openVerificationModal,
}) => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState('');
    const { superuser } = useCurrentUser();

    const filteredGirls = searchValue
        ? girls.filter((girl) => girl.name.toUpperCase().includes(searchValue.toUpperCase()))
        : girls;

    const data = saloons.map((l) => ({
        ...l,
        girls: filteredGirls.filter((q) => q.saloonId === l.id),
    }));

    const filteredData = searchValue ? data.filter((saloon) => saloon.girls.length) : data;

    return (
        <div className={ styles.container }>
            <div className={ styles.head }>
                <HeaderSearch
                    placeholder={ t('tables.girlsSearch') }
                    value={ searchValue }
                    onChange={ setSearchValue }
                />

                { superuser && (
                    <Button
                        dataTestId="createbtn"
                        onClick={ () => openSaloonModal() }
                        view="primary"
                        leftAddons={ <PlusS /> }
                        size="xs"
                    >
                        { t('createSaloon') }
                    </Button>
                ) }
            </div>

            <div className={ styles.content }>
                { filteredData.map((saloon) => (
                    <CollapsableRow
                        label={ t('saloon') }
                        expanded={ true }
                        key={ saloon.id }
                        content={ (
                            <Header
                                title={ saloon.title }
                                onClick={ () => (superuser ? openSaloonModal(saloon.id) : undefined) }
                            />
                        ) }
                        addons={ (
                            <Button
                                leftAddons={ <PlusS /> }
                                size="xs"
                                onClick={ () => openGirlModal(saloon.id) }
                                view="secondary"
                            >
                                { t('addGirl') }
                            </Button>
                        ) }
                    >
                        <GirlsList
                            openGirlModal={ openGirlModal }
                            openOptionsModal={ openOptionsModal }
                            openVerificationModal={ openVerificationModal }
                            girls={ saloon.girls }
                            refetch={ refetch }
                        />

                        { !saloon.girls.length && (
                            <EmptyList onClick={ () => openGirlModal(saloon.id) } />
                        ) }
                    </CollapsableRow>
                )) }
            </div>
        </div>
    );
};
