import React from 'react';
import { FlexColumns, Select, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { enumToOptions } from '@lovelyvoyager/services/dist/utils/enum-to-options';
import { ScheduleTypeEnum } from '@lovelyvoyager/types/dist/girl/enum/schedule-type.enum';
import { UpdateScheduleTemplateDto } from '@lovelyvoyager/types/dist/girl/update-schedule-template.dto';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { getOptionForTimeSelect } from 'src/utils/convert-time';

type Props = {
    recordId: number;
    initialValues: Partial<UpdateScheduleTemplateDto>;
    close?: () => void;
    t: TFunction;
};

export const ScheduleTemplateModal: React.FC<Props> = ({
    recordId, initialValues, close, t,
}) => {
    const { girls } = useApi();

    const {
        updating,
        save,
        removing,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: girls.updateScheduleTemplate,
        close,
    });

    return (
        <RestForm
            recordId={ +recordId }
            title={ `${t('scheduleTemplateForm')}` }
            initialValues={ serializeRecord(UpdateScheduleTemplateDto, initialValues) }
            validate={ validateByDto(t) }
            close={ close }
            save={ save }
            softError={ softError }
            updating={ updating || removing }
            t={ t }
        >
            { ({
                values, setFieldValue, touched, errors,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Select
                        selected={ values.type }
                        label={ t('status') }
                        options={ enumToOptions(ScheduleTypeEnum, t, 'scheduleType') }
                        onChange={ ({ selected }) => setFieldValue('type', selected.key) }
                    />

                    { values.type === ScheduleTypeEnum.CUSTOM_TIME && (
                        <React.Fragment>
                            <Select
                                dataTestId="start"
                                label={ t('from') }
                                options={ getOptionForTimeSelect() }
                                onChange={ ({ selected }) => setFieldValue('start', selected.key) }
                                selected={ values.start }
                                error={ touched.start && errors.start }
                                required={ true }
                                block={ true }
                            />

                            <Select
                                dataTestId="end"
                                label={ t('to') }
                                options={ getOptionForTimeSelect() }
                                onChange={ ({ selected }) => setFieldValue('end', selected.key) }
                                selected={ values.end }
                                error={ touched.end && errors.end }
                                required={ true }
                                block={ true }
                            />
                        </React.Fragment>
                    ) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
