import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';

import '@alphakits/ui/dist/index.css';
import './index.css';

ReactDOM.render(
    <React.StrictMode>
        <App />
        <div id="modal-root" />
    </React.StrictMode>,
    document.getElementById('root'),
);
