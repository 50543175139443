import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@alphakits/ui/dist';

import styles from './index.module.css';

type Props = {
    onClick: () => void;
};

export const EmptyList: React.FC<Props> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <div
            style={ {
                display: 'flex',
                alignItems: 'center',
            } }
        >
            <Typography.Text tag="div" className={ styles.empty_location } view="title">
                { t('noGirlsInSaloon') }. &nbsp;&nbsp;
            </Typography.Text>
            <Button size="xs" onClick={ onClick } view="link">
                { t('addGirl') }
            </Button>
        </div>
    );
};
