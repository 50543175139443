import React from 'react';
import {
    Divider,
    FlexColumns,
    InfoBlockItem,
    Textarea,
    Typography,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { ReviewAnswerDto } from '@lovelyvoyager/types/dist/review/review-answer.dto';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    recordId: number;
    close: () => void;
    t: TFunction;
};

export const ReviewModal: React.FC<Props> = ({ recordId, close, t }) => {
    const { reviews } = useApi();

    const { loading, record, error } = useGetOne(recordId, reviews.getOne);

    const {
        updating,
        save,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: reviews.updateAnswer,
        close,
    });

    const type = record.girl?.name ? 'girl' : 'saloon';

    return (
        <RestForm
            recordId={ +recordId }
            title={ `${record.name}, ${record.delta}` }
            initialValues={ serializeRecord(ReviewAnswerDto, record) }
            validate={ validateByDto(t) }
            close={ close }
            save={ save }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating }
            t={ t }
        >
            { ({
                values,
                handleChange, // errors, touched, setFieldValue,
            }) => (
                <React.Fragment>
                    { type === 'girl' ? (
                        <React.Fragment>
                            <InfoBlockItem label="Модель" value={ record.girl.name } />
                            <Divider size="m" />
                            <InfoBlockItem
                                label="Впечатление"
                                value={ record.girlRating.impression }
                            />
                            <InfoBlockItem
                                label="Вовлечченность"
                                value={ record.girlRating.involvement }
                            />
                            <InfoBlockItem
                                label="Соответствие фото"
                                value={ record.girlRating.photoMatch }
                            />
                            <InfoBlockItem label="Фигура" value={ record.girlRating.shape } />
                            <InfoBlockItem
                                label="Соотношение цена / качество"
                                value={ record.girlRating.quality }
                            />
                            <InfoBlockItem label="Сервис" value={ record.girlRating.service } />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <InfoBlockItem label="Салон" value={ record.saloon.title } />
                            <Divider size="m" />
                            <InfoBlockItem label="Чистота" value={ record.saloonRating.clean } />
                            <InfoBlockItem
                                label="Девушки"
                                value={ record.saloonRating.girlsQuality }
                            />
                            <InfoBlockItem label="Интерьер" value={ record.saloonRating.interior } />
                            <InfoBlockItem label="Персонал" value={ record.saloonRating.personal } />
                            <InfoBlockItem label="Расположение" value={ record.saloonRating.place } />
                            <InfoBlockItem
                                label="Приватность"
                                value={ record.saloonRating.privacy }
                            />
                        </React.Fragment>
                    ) }

                    <Divider size="m" />

                    <Typography.Text>{ record.text }</Typography.Text>

                    <br />

                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Textarea
                            label={ t('answer') }
                            value={ values.answer }
                            onChange={ handleChange('answer') }
                            required={ true }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
