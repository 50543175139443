import React from 'react';
import {
    FlexColumns, Input, Textarea, useCrudFormRequests, useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { BlacklistRO } from '@lovelyvoyager/types/dist/blacklist/blacklist.ro';
import { UpdateBlacklistDto } from '@lovelyvoyager/types/dist/blacklist/update-blacklist.dto';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { checkAccess } from 'src/utils/check-access';

type Props = {
    close: () => void;
    t: TFunction;
    recordId: number;
};

export const BlacklistModal: React.FC<Props> = ({ close, t, recordId }) => {
    const { blacklists } = useApi();

    const { profile } = useCurrentUser();

    const { record, loading, error } = useGetOne(+recordId, blacklists.getOne);

    const {
        updating,
        removing,
        save,
        remove,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: recordId ? blacklists.update : blacklists.create,
        removeRequest: blacklists.remove,
        close,
    });

    return (
        <RestForm
            recordId={ recordId }
            t={ t }
            loading={ loading }
            updating={ updating || removing }
            save={ save }
            error={ error }
            softError={ softError }
            remove={ checkAccess(profile.id, (record as BlacklistRO).profileId) ? remove : undefined }
            validate={ validateByDto(t) }
            title={ recordId ? t('blacklistUpdate') : t('blacklistAdd') }
            close={ close }
            initialValues={ serializeRecord(UpdateBlacklistDto, record) }
        >
            { ({
                values, errors, touched, handleChange,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            label={ t('phone') }
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            error={ touched.phone && errors.phone }
                            required={ true }
                            disabled={
                                !checkAccess(
                                    profile.id,
                                    recordId ? (record as BlacklistRO).profileId : profile.id,
                                )
                            }
                        />
                        <Textarea
                            label={ t('comment') }
                            value={ values.comment }
                            onChange={ handleChange('comment') }
                            error={ touched.comment && errors.comment }
                            required={ true }
                            disabled={
                                !checkAccess(
                                    profile.id,
                                    recordId ? (record as BlacklistRO).profileId : profile.id,
                                )
                            }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
