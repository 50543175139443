export const convertMinutesToHHMM = (minutes: number) => {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;

    const hh = h < 10 ? `0${h}` : h;
    const mm = m < 10 ? `0${m}` : m;

    return `${hh}:${mm}`;
};

export const getOptionForTimeSelect = () => {
    const options = [];

    for (let index = 0; index < 1440; index += 5) {
        options.push(convertMinutesToHHMM(index));
    }

    return options.map((o) => ({
        key: o,
        content: o,
    }));
};
