import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, FlexColumns, IconButton, Image, TableCell, Tooltip,
} from '@alphakits/ui/dist';
import { GirlRO } from '@lovelyvoyager/types/dist/girl/girl.ro';
import { AllertMarkM, AllertMarkS, EditS } from 'alpha-cli-icons';
import { VerificationModalProps } from 'src/modals/verification';

import { SwitchVisibility } from './switch-visibility';

import styles from './index.module.css';

type Props = {
    girls: GirlRO[];
    refetch: () => void;
    openGirlModal: (saloonId: number, girlId?: number) => void;
    openOptionsModal: (girlId: number) => void;
    openVerificationModal: (props: VerificationModalProps) => void;
};

const Alert = ({
    girl,
    openVerificationModal,
}: {
    girl: GirlRO;
    openVerificationModal: Props['openVerificationModal'];
}) => {
    const { t } = useTranslation();

    const {
        title, color, description, message,
    } = useMemo(() => {
        if (!girl.accepted) {
            return {
                title: t('accepted.title'),
                color: '#FFB45D',
                description: t('accepted.description'),
            };
        }

        if (girl.blocked) {
            return {
                title: t('blocked.title'),
                color: '#FF5D7B',
                description: t('blocked.description'),
            };
        }

        if (girl.moderation) {
            return {
                title: t('moderation.title'),
                color: girl.moderation.comment ? '#FF5D7B' : '#FFB45D',
                description: t('moderation.description'),
                message: girl.moderation.comment || '',
            };
        }

        return {};
    }, [girl, t]);

    if (!title) return null;

    return (
        <Tooltip content={ title }>
            <React.Fragment>
                <Button
                    key={ girl.id }
                    view="ghost"
                    leftAddons={ <AllertMarkM /> }
                    onClick={ (e) => {
                        e.preventDefault();
                        openVerificationModal({
                            t,
                            title,
                            description,
                            message,
                        });
                    } }
                    size="xs"
                    style={ { color } }
                >
                    { title }
                </Button>
            </React.Fragment>
        </Tooltip>
    );
};

export const GirlsList: React.FC<Props> = ({
    girls,
    refetch,
    openGirlModal,
    openOptionsModal,
    openVerificationModal,
}) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            { girls.map((girl) => (
                <FlexColumns columns={ 4 } gc={ 0 } gr={ 0 }>
                    <TableCell
                        className={ styles.col }
                        title={ (
                            <React.Fragment>
                                { girl.name } &nbsp;
                                { girl.accepted && (
                                    <IconButton
                                        key={ girl.id }
                                        view="ghost"
                                        onClick={ () => openGirlModal(girl.saloonId, girl.id) }
                                        icon={ EditS }
                                        size="s"
                                    />
                                ) }
                            </React.Fragment>
                        ) }
                        addon={ <Image view="ellipse" src={ girl.photo } /> }
                    />

                    <TableCell
                        className={ styles.col }
                        title={ (
                            <React.Fragment>
                                { t('served', { options: girl.optionsLength }) } &nbsp;
                                { !girl.optionsLength && <AllertMarkS color="red" /> }
                            </React.Fragment>
                        ) }
                        subtitle={ (
                            <Button
                                onClick={ () => openOptionsModal(girl.id) }
                                style={ { fontSize: 12, minHeight: '20px' } }
                                size="xs"
                                view="link"
                            >
                                { t('addOption') }
                            </Button>
                        ) }
                    />

                    <TableCell
                        className={ styles.col }
                        title={ (
                            <SwitchVisibility
                                refetch={ refetch }
                                girlId={ girl.id }
                                visible={ girl.visible }
                            />
                        ) }
                    />

                    <TableCell
                        className={ styles.col }
                        title={ <Alert girl={ girl } openVerificationModal={ openVerificationModal } /> }
                    />
                </FlexColumns>
            )) }
        </React.Fragment>
    );
};
