import React from 'react';
import {
    Divider,
    FlexColumns,
    Input,
    Padding,
    Select,
    Switch,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { validateByDto } from '@lovelyvoyager/services/dist/utils';
import { enumToOptions } from '@lovelyvoyager/services/dist/utils/enum-to-options';
import { AddOptionDto } from '@lovelyvoyager/types/dist/girl/add-option.dto';
import { OptionReactionEnum } from '@lovelyvoyager/types/dist/girl/enum/option-reaction.enum';
import { OptionTypeEnum } from '@lovelyvoyager/types/dist/girl/enum/option-type.enum';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    recordId: number;
    close?: () => void;
    t: TFunction;
};

export const OptionsModal: React.FC<Props> = ({ recordId, close, t }) => {
    const { girls } = useApi();

    const { loading, record, error } = useGetOne(recordId, girls.findOptions);

    const {
        updating,
        save,
        remove,
        removing,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: girls.updateOptions,
        close,
    });

    return (
        <RestForm
            title={ t('girlOptions') }
            initialValues={ { options: record } }
            validate={ validateByDto(t) }
            close={ close }
            error={ error }
            loading={ loading }
            remove={ remove }
            save={ save }
            softError={ softError }
            updating={ updating || removing }
            t={ t }
        >
            { ({ values, setFieldValue }) => {
                const pushOption = (option: AddOptionDto['options'][0]) => {
                    setFieldValue('options', [...values.options, option]);
                };

                const updateOptionValue = (optionName, property, value) => {
                    const option = values.options.filter((o) => o.option === optionName)[0];
                    const other = values.options.filter((o) => o.option !== optionName);

                    setFieldValue('options', [...other, { ...option, [property]: value }]);
                };

                const removeOption = (optionName) => {
                    setFieldValue(
                        'options',
                        values.options.filter((o) => o.option !== optionName),
                    );
                };

                return (
                    <React.Fragment>
                        { enumToOptions(OptionTypeEnum, t, 'girlsOptions').map((option) => {
                            const current = values.options.filter(
                                (o) => o.option === option.key,
                            )[0];

                            return (
                                <FlexColumns columns={ 1 } gr={ 10 } gc={ 0 }>
                                    <Divider size="s" />

                                    <FlexColumns columns={ 4 } gr={ 0 } gc={ 10 }>
                                        <div
                                            style={ {
                                                margin: '25px 0 0 0',
                                            } }
                                        >
                                            <Switch
                                                label={ option.content }
                                                checked={ !!current }
                                                onChange={ () => {
                                                    if (current) {
                                                        removeOption(option.key);
                                                    } else {
                                                        pushOption({
                                                            option: OptionTypeEnum[
                                                                option.key.toUpperCase()
                                                            ],
                                                            price: 0,
                                                            reaction: OptionReactionEnum.DO,
                                                            comment: '',
                                                        });
                                                    }
                                                } }
                                            />
                                        </div>

                                        <Select
                                            selected={ current && current.reaction }
                                            disabled={ !current }
                                            label={ t('reaction') }
                                            options={ enumToOptions(
                                                OptionReactionEnum,
                                                t,
                                                'girlsOptionsReactionType',
                                            ) }
                                            onChange={ ({ selected }) =>
                                                updateOptionValue(
                                                    option.key,
                                                    'reaction',
                                                    selected.key,
                                                ) }
                                        />

                                        <Input
                                            dataTestId="price"
                                            disabled={ !current }
                                            label={ `${t('additional_price')}, €` }
                                            value={ current ? `${current.price}` : '' }
                                            onChange={ (e) =>
                                                updateOptionValue(
                                                    option.key,
                                                    'price',
                                                    e.target.value,
                                                ) }
                                            type="number"
                                            block={ true }
                                        />

                                        <Input
                                            dataTestId="price"
                                            disabled={ !current }
                                            label={ `${t('comment')}` }
                                            value={ current ? `${current.comment}` : '' }
                                            onChange={ (e) =>
                                                updateOptionValue(
                                                    option.key,
                                                    'comment',
                                                    e.target.value,
                                                ) }
                                            block={ true }
                                        />
                                    </FlexColumns>
                                    <Padding padding="0" />
                                </FlexColumns>
                            );
                        }) }
                    </React.Fragment>
                );
            } }
        </RestForm>
    );
};
