import React from 'react';
import {
    FlexColumns, Input, Switch, useCrudFormRequests, useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { CreateUserDto } from '@lovelyvoyager/types/dist/user/create-user.dto';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    recordId?: number;
    close: () => void;
    t: TFunction;
};

export const UserModal: React.FC<Props> = ({ close, t, recordId }) => {
    const { users } = useApi();
    const { current } = useCurrentUser();

    const isCurrentMaster = current.user.master || current.user.id === 999999999;
    const isCurrentSame = recordId === current.user.id;

    const { loading, record, error } = useGetOne(recordId, users.getOne);

    const canEdit = isCurrentSame || isCurrentMaster;

    const title = recordId ? `${record.name} ${record.surname}` : t('createUser');

    const {
        updating,
        save,
        remove,
        removing,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: recordId ? users.update : users.create,
        removeRequest: users.remove,
        close,
    });

    return (
        <RestForm
            recordId={ recordId }
            initialValues={ serializeRecord(CreateUserDto, record) }
            validate={ validateByDto(t) }
            save={ save }
            error={ error }
            title={ title }
            close={ close }
            remove={ isCurrentMaster && !isCurrentSame && remove }
            loading={ loading }
            updating={ updating }
            removing={ removing }
            softError={ softError }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 24 } gc={ 0 }>
                        <Input
                            dataTestId="name"
                            label={ t('name') }
                            value={ values.name }
                            onChange={ handleChange('name') }
                            error={ touched.name && errors.name }
                            required={ true }
                            disabled={ !canEdit }
                            block={ true }
                        />
                        <Input
                            dataTestId="surname"
                            label={ t('surname') }
                            value={ values.surname }
                            onChange={ handleChange('surname') }
                            error={ touched.surname && errors.surname }
                            required={ true }
                            disabled={ !canEdit }
                            block={ true }
                        />
                        <Input
                            dataTestId="email"
                            label={ t('email') }
                            value={ values.email }
                            onChange={ handleChange('email') }
                            error={ touched.email && errors.email }
                            required={ true }
                            disabled={ !canEdit }
                            block={ true }
                        />
                        <Input
                            dataTestId="phone"
                            label={ t('phone') }
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            disabled={ !canEdit }
                            block={ true }
                        />
                        { isCurrentMaster && !isCurrentSame && (
                            <Switch
                                label={ t('fullAccess') }
                                hint={ t('fullAccessHint') }
                                onChange={ (_, { checked }) => setFieldValue('master', checked) }
                                checked={ values.master }
                            />
                        ) }
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
