import { useState } from 'react';
import { ToastNotifier } from '@alphakits/ui/dist';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);

            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            ToastNotifier({
                title: 'Ошибка',
                text: error?.message || error,
                view: 'negative',
            });

            return initialValue;
        }
    });
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;

            setStoredValue(valueToStore);

            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            ToastNotifier({
                title: 'Ошибка',
                text: error?.message || error,
                view: 'negative',
            });
        }
    };

    return [storedValue, setValue] as const;
};
