import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { ReviewModal } from 'src/modals/review';
import { useApi } from 'src/providers/api/context';

import { columns } from './columns';

export const ReviewsPage: React.FC = () => {
    const { reviews } = useApi();
    const { openModal } = useContext(ModalContext);
    const openReviewModal = openModal(ReviewModal);

    const { t } = useTranslation();

    return (
        <Table.TableComponent
            searchPlaceholder={ t('tables.reviewsSearch') }
            columns={ columns(t) }
            onRowClick={ ({ id }, refetch) => openReviewModal({ recordId: id }, refetch) }
            promise={ reviews.query }
            language="ru"
        />
    );
};
