/* eslint-disable complexity */
import React from 'react';
import {
    CalendarInput,
    Checkbox,
    Collapse,
    convertToOptions,
    Divider,
    FlexColumns,
    formatDate,
    ImageUploader,
    Input,
    ListAllert,
    Select,
    SuperListHeader,
    Switch,
    Textarea,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { MIN_AGE } from '@lovelyvoyager/services/dist/constants';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { enumToOptions } from '@lovelyvoyager/services/dist/utils/enum-to-options';
import { heights, weights } from '@lovelyvoyager/services/dist/utils/shared-options';
import { CreateGirlDto } from '@lovelyvoyager/types/dist/girl/create-girl.dto';
import { BustSizeEnum } from '@lovelyvoyager/types/dist/girl/enum/bust-size.enum';
import { BustTypeEnum } from '@lovelyvoyager/types/dist/girl/enum/bust-type.enum';
import { EthnicityEnum } from '@lovelyvoyager/types/dist/girl/enum/ethnicity.enum';
import { GirlLanguageEnum } from '@lovelyvoyager/types/dist/girl/enum/girl-language.enum';
import { GirlTypeEnum } from '@lovelyvoyager/types/dist/girl/enum/girl-type.enum';
import { HairColorEnum } from '@lovelyvoyager/types/dist/girl/enum/hair-color.enum';
import { HairLengthEnum } from '@lovelyvoyager/types/dist/girl/enum/hair-length.enum';
import { HairPublicEnum } from '@lovelyvoyager/types/dist/girl/enum/hair-public.enum';
import { OrientationEnum } from '@lovelyvoyager/types/dist/girl/enum/orientation.enum';
import { SaloonRO } from '@lovelyvoyager/types/dist/saloon/saloon.ro';
import { ImageTypeEnum } from '@lovelyvoyager/types/dist/upload/enum/image-type.enum';
import { parse, subYears } from 'date-fns';
import { TFunction } from 'i18next';
import { Video } from 'src/components/video';
import { useApi } from 'src/providers/api/context';

import 'plyr-react/plyr.css';

type Props = {
    recordId?: number;
    saloons: SaloonRO[];
    saloonId: number;
    close?: () => void;
    t: TFunction;
};

export const GirlModal: React.FC<Props> = ({
    recordId, saloonId, saloons, close, t,
}) => {
    const { girls, moderation, uploads } = useApi();

    const { loading, record, error } = useGetOne(recordId, girls.getOneGirl);

    const {
        updating,
        save,
        remove,
        removing,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: recordId ? moderation.create : girls.createGirl,
        removeRequest: girls.removeGirl,
        close,
    });

    return (
        <RestForm
            recordId={ recordId }
            title={ recordId ? record.name : t('girlForm') }
            initialValues={ serializeRecord(
                CreateGirlDto,
                record.moderation
                    ? record.moderation
                    : {
                        ...record,
                        saloonId,
                        birthday: '2000-01-01',
                    },
            ) }
            validate={ validateByDto(t) }
            close={ close }
            remove={ remove }
            save={ save }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <React.Fragment>
                    { record.moderation && <ListAllert text={ t('moderation.title') } /> }

                    <SuperListHeader text={ t('mainInfo') } />

                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            dataTestId="name"
                            label={ t('name') }
                            value={ values.name }
                            onChange={ handleChange('name') }
                            error={ touched.name && errors.name }
                            hint={ t('nickname') }
                            required={ true }
                            block={ true }
                        />

                        { values.saloonId && (
                            <Select
                                selected={ values.saloonId }
                                label={ t('saloon') }
                                options={ convertToOptions(saloons) }
                                onChange={ ({ selected }) => setFieldValue('saloonId', selected.key) }
                            />
                        ) }

                        { !values.saloonId && (
                            <React.Fragment>
                                <Input
                                    dataTestId="address"
                                    label={ t('address') }
                                    value={ values.address }
                                    onChange={ handleChange('address') }
                                    hint={ t('requiredStreetCity') }
                                    error={ touched.address && errors.address }
                                    required={ true }
                                    block={ true }
                                />
                                <Input
                                    dataTestId="phone"
                                    label={ t('phone') }
                                    value={ values.phone }
                                    onChange={ handleChange('phone') }
                                    error={ touched.phone && errors.phone }
                                    required={ !values.saloonId }
                                    hint={
                                        values.saloonId
                                            ? t('willShowSaloonPhone')
                                            : t('willShowThisPhone')
                                    }
                                    block={ true }
                                />
                            </React.Fragment>
                        ) }

                        <Checkbox
                            dataTestId="whatsapp"
                            label={ t('whatsapp') }
                            checked={ values.whatsapp }
                            onChange={ (e, { checked }) => setFieldValue('whatsapp', checked) }
                        />

                        <Input
                            dataTestId="telegram"
                            label={ t('telegram') }
                            value={ values.telegram }
                            onChange={ handleChange('telegram') }
                            error={ touched.telegram && errors.telegram }
                            hint={ t('enterTelegram') }
                            block={ true }
                        />
                    </FlexColumns>

                    <Divider size="l" />

                    <SuperListHeader text={ t('price') } />

                    <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                        <Input
                            dataTestId="outdoorPrice"
                            label={ `${t('outdoorPrice')}, € ` }
                            value={ `${values.outdoorPrice}` }
                            onChange={ handleChange('outdoorPrice') }
                            error={ touched.outdoorPrice && !!errors.outdoorPrice }
                            type="number"
                            block={ true }
                        />
                        <Input
                            dataTestId="indoorPrice"
                            label={ `${t('indoorPrice')}, € ` }
                            value={ `${values.indoorPrice}` }
                            onChange={ handleChange('indoorPrice') }
                            type="number"
                            error={ touched.indoorPrice && !!errors.indoorPrice }
                            block={ true }
                        />
                    </FlexColumns>

                    <Divider size="l" />

                    <SuperListHeader text={ t('params') } />

                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 20 }>
                        <Select
                            selected={ values.type }
                            label={ t('type') }
                            options={ enumToOptions(GirlTypeEnum, t, 'type') }
                            onChange={ ({ selected }) => setFieldValue('type', selected.key) }
                            error={ touched.type && errors.type }
                            required={ true }
                        />

                        <CalendarInput
                            calendarProps={ { t } }
                            value={ formatDate(
                                parse(values.birthday, 'yyyy-MM-dd', new Date()),
                                'dd-MM-yyyy',
                            ) }
                            label={ t('birthday') }
                            onChange={ (e, { date }) => {
                                setFieldValue('birthday', formatDate(date, 'yyyy-MM-dd'));
                            } }
                            maxDate={ subYears(new Date(), MIN_AGE).getTime() }
                            block={ true }
                            required={ true }
                            error={ touched.birthday && errors.birthday }
                        />

                        <Select
                            selected={ values.ethnicity }
                            label={ t('ethnicity') }
                            options={ enumToOptions(EthnicityEnum, t, 'ethnicity') }
                            onChange={ ({ selected }) => setFieldValue('ethnicity', selected.key) }
                            required={ true }
                            error={ touched.ethnicity && errors.ethnicity }
                        />

                        <Select
                            selected={ values.weight }
                            label={ t('weight') }
                            options={ convertToOptions(weights) }
                            onChange={ ({ selected }) => setFieldValue('weight', selected.key) }
                            error={ touched.weight && errors.weight }
                            required={ true }
                        />

                        <Select
                            selected={ values.height }
                            label={ t('height') }
                            options={ convertToOptions(heights) }
                            onChange={ ({ selected }) => setFieldValue('height', selected.key) }
                            error={ touched.height && errors.height }
                            required={ true }
                        />

                        <Select
                            selected={ values.bustSize }
                            label={ t('bustSize') }
                            options={ enumToOptions(BustSizeEnum, t, 'bustSize') }
                            onChange={ ({ selected }) => setFieldValue('bustSize', selected.key) }
                            error={ touched.bustSize && errors.bustSize }
                            required={ true }
                        />

                        <Select
                            selected={ values.bustType }
                            label={ t('bustType') }
                            options={ enumToOptions(BustTypeEnum, t, 'bustType') }
                            onChange={ ({ selected }) => setFieldValue('bustType', selected.key) }
                            error={ touched.bustType && errors.bustType }
                            required={ true }
                        />

                        <Select
                            selected={ values.orientation }
                            label={ t('orientation') }
                            options={ enumToOptions(OrientationEnum, t, 'orientation') }
                            onChange={ ({ selected }) => setFieldValue('orientation', selected.key) }
                            error={ touched.orientation && errors.orientation }
                            required={ true }
                        />

                        <Select
                            selected={ values.hairColor }
                            label={ t('hairColor') }
                            options={ enumToOptions(HairColorEnum, t, 'hairColor') }
                            onChange={ ({ selected }) => setFieldValue('hairColor', selected.key) }
                            error={ touched.hairColor && errors.hairColor }
                            required={ true }
                        />

                        <Select
                            selected={ values.hairLength }
                            label={ t('hairLength') }
                            options={ enumToOptions(HairLengthEnum, t, 'hairLength') }
                            onChange={ ({ selected }) => setFieldValue('hairLength', selected.key) }
                            error={ touched.hairLength && errors.hairLength }
                            required={ true }
                        />

                        <Select
                            selected={ values.publicHair }
                            label={ t('publicHair') }
                            options={ enumToOptions(HairPublicEnum, t, 'publicHair') }
                            onChange={ ({ selected }) => setFieldValue('publicHair', selected.key) }
                            error={ touched.publicHair && errors.publicHair }
                            required={ true }
                        />
                        <Select
                            selected={ values.languages }
                            label={ t('languages') }
                            options={ enumToOptions(GirlLanguageEnum, t) }
                            onChange={ ({ selectedMultiple }) => {
                                setFieldValue(
                                    'languages',
                                    selectedMultiple.map((i) => i.key),
                                );
                            } }
                            error={ touched.languages && errors.languages?.toString() }
                            multiple={ true }
                            required={ true }
                        />
                    </FlexColumns>

                    <Divider size="l" />
                    <SuperListHeader text={ t('extra') } />

                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 20 }>
                        <Switch
                            label={ t('tattoo') }
                            onChange={ (_, { checked }) => setFieldValue('tattoo', checked) }
                            checked={ values.tattoo }
                        />

                        <Switch
                            label={ t('piercing') }
                            onChange={ (_, { checked }) => setFieldValue('piercing', checked) }
                            checked={ values.piercing }
                        />

                        <Switch
                            label={ t('smoker') }
                            onChange={ (_, { checked }) => setFieldValue('smoker', checked) }
                            checked={ values.smoker }
                        />

                        <Switch
                            label={ t('pornstar') }
                            onChange={ (_, { checked }) => setFieldValue('pornstar', checked) }
                            checked={ values.pornstar }
                        />
                    </FlexColumns>

                    <Divider size="l" />

                    <SuperListHeader text={ t('params') } />

                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 20 }>
                        <Textarea
                            label={ t('aboutGirl') }
                            value={ values.about }
                            onChange={ handleChange('about') }
                            error={ touched.about && errors.about }
                            required={ true }
                        />
                    </FlexColumns>

                    <Divider size="l" />

                    <ImageUploader
                        title={ `${t('photoUploaderTitle')} *` }
                        subtitle={ t('photoUploaderSubtitle') }
                        value={ values.photos || [] }
                        main={ values.photo }
                        onUpdate={ (value) => {
                            if (!values.photo) {
                                setFieldValue('photo', value[0]);
                            }
                            setFieldValue('photos', value);
                        } }
                        setMain={ (value) => setFieldValue('photo', value) }
                        error={ touched.photo && errors.photo }
                        uploadRequest={ uploads.uploadImage }
                        type={ ImageTypeEnum.GIRL }
                        multiple={ true }
                    />

                    <br />

                    <Collapse
                        collapsedLabel={ t('addLinkVideo') }
                        expandedLabel={ t('hiddenLinkVideo') }
                        dataTestId="addLinkVideo"
                    >
                        <Video
                            onUpdate={ (value) => setFieldValue('video', value) }
                            value={ values.video }
                        />
                    </Collapse>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
