import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@alphakits/ui/dist';
import { ScheduleTypeEnum } from '@lovelyvoyager/types/dist/girl/enum/schedule-type.enum';

type Props = {
    type: ScheduleTypeEnum;
    start?: string;
    end?: string;
    onClick: () => void;
};

export const ScheduleCell: React.FC<Props> = ({
    type, start, end, onClick,
}) => {
    const { t } = useTranslation();
    const view = useMemo((): ButtonProps['view'] => {
        if (type === ScheduleTypeEnum.WEEKEND) return 'secondary';

        return 'outlined';
    }, [type]);

    return (
        <Button block={ true } onClick={ onClick } size="xs" view={ view }>
            { type === ScheduleTypeEnum.CUSTOM_TIME
                ? `${start} - ${end}`
                : t(`options.scheduleType.${type}`) }
        </Button>
    );
};
