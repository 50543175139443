import React, { forwardRef, useRef, useState } from 'react';
import { IconButton, SuperListHeader, ToastNotifier } from '@alphakits/ui/dist';
import { getServiceError } from '@lovelyvoyager/services/dist/utils/get-service-error';
import { PlusS, TrashM } from 'alpha-cli-icons';
import Plyr from 'plyr-react';
import { useApi } from 'src/providers/api/context';

type Props = {
    onUpdate: (src: null | string) => void;
    value: null | string;
    className?: string;
};

export const Video = forwardRef<HTMLInputElement, Props>(({ value, onUpdate, className }, ref) => {
    const { uploads } = useApi();

    const [loading, setLoading] = useState(false);
    const innerRef = useRef<HTMLInputElement>();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (!file) return;

        const data = new FormData();

        data.append('file', file);

        setLoading(true);

        uploads
            .uploadVideo({
                data,
                onUploadProgress: (progress) => progress,
            })
            .then(({ data: { url } }) => {
                onUpdate(url);
            })
            .catch((err) => {
                const { message } = getServiceError(err);

                ToastNotifier({ title: message, view: 'negative', offset: 30 });
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className={ className }>
            <input
                type="file"
                style={ { height: 0, position: 'absolute' } }
                ref={ innerRef }
                onChange={ handleChange }
                multiple={ false }
            />

            <input
                type="file"
                style={ { height: 0, position: 'absolute' } }
                ref={ ref }
                onChange={ handleChange }
                multiple={ false }
            />

            <SuperListHeader
                text={ value ? 'Remove video' : 'Add video' }
                leftAddons={
                    value ? (
                        <IconButton onClick={ () => onUpdate(null) } icon={ TrashM } />
                    ) : (
                        <IconButton
                            onClick={ () => innerRef.current.click() }
                            icon={ PlusS }
                            loading={ loading }
                        />
                    )
                }
            />

            { value && (
                <Plyr
                    source={ {
                        type: 'video',
                        sources: [
                            {
                                src: value,
                                provider: 'html5',
                            },
                        ],
                    } }
                />
            ) }
        </div>
    );
});
