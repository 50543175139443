import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_DE } from './de';

const detectOptions = {
    order: ['navigator', 'cookie'],
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            de: {
                translation: TRANSLATIONS_DE,
            },
        },
        detection: detectOptions,
        fallbackLng: 'de',
    });
