import React from 'react';
import { ListAllert, Padding, Typography } from '@alphakits/ui/dist';
import { TFunction } from 'i18next';

export type VerificationModalProps = {
    title: string;
    description: string;
    message?: string;
    t: TFunction;
};

export const VerificationModal: React.FC<VerificationModalProps> = ({
    title, description, message, t,
}) => (
    <Padding padding="32px">
        <Typography.Title view="small" tag="div">
            { title }
        </Typography.Title>
        <br />

        { message ? (
            <Typography.Text>
                { t('moderation.alert') }
                <br />
                <br />

                <ListAllert text={ message } />
            </Typography.Text>
        ) : (
            <Typography.Text>
                { description }
            </Typography.Text>
        ) }
        <br />
        <br />
        <Typography.Text>
            { t('haveQuestions') } <b>8 800 544 344</b>
        </Typography.Text>
    </Padding>
);

//  ? }
